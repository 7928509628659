import { ObjectID } from 'mongodb'
import { AccountInstanceSummary } from './accounts'
import { UserImpact } from './user'
import {AccountBase} from 'plaid'

export type PlaidTxLocation = {
  address?: any;
  city?: any;
  country?: any;
  lat?: any;
  lon?: any;
  postal_code?: any;
  region?: any;
  store_number?: any;
}

export type PlaidTxMeta = {
  by_order_of?: any;
  payee?: any;
  payer?: any;
  payment_method?: any;
  payment_processor?: any;
  ppd_id?: any;
  reason?: any;
  reference_number?: any;
}

export type MatchRatingInfo = {
  rating: string;
  label: string;
  color: string;
}

export interface PlaidTransaction {
  _id?: string;
  account_id?: string;
  account_owner?: any;
  amount: number;
  authorized_date?: any;
  category: string[];
  category_id?: string;
  date: string;
  iso_currency_code: string;
  location: PlaidTxLocation;
  merchant_name?: string;
  name: string;
  payment_channel?: string;
  payment_meta: PlaidTxMeta;
  pending: boolean;
  pending_transaction_id?: any;
  transaction_code?: any;
  transaction_id: string;
  transaction_type: string;
  unofficial_currency_code?: any;
}

enum MatchType {
  Charity = "CHARITY",
  LocalBiz = "LOCALBIZ",
  Search = "SEARCH",
  Known = "KNOWN",
  NoMatches = "NO_MATCHES",
  Moderation = "MODERATION"
}

export type FinastraAccountDetails = {
  accountId: string;
  formattedAccountNumber: string;
  accountType: "CHK" | "SAV" | "MM" | "CD" | "CARD" | "LOC" | "LOAN" | "NONE";
  nickname: string;
  balances:{
      type: string;
      amount: number;
    }[];
  accountNumber: string;
  alternateAccountNumber?: string;
  coreComponents: {
    number: string;
    type: string;
    subtype: string;
    suffix: string;
  };
  micr: string;
  openDate: string; // "2020-01-02"
  privileges: {
    internalTransferFromAllowed: boolean;
    internalTransferToAllowed: boolean;
    rdcAllowed: boolean;
    c2cSendAllowed: boolean;
    c2cReceiveAllowed: boolean;
    p2pSendAllowed: boolean;
    p2pReceiveAllowed: boolean;
    externalTransferSendAllowed: boolean;
    externalTransferReceiveAllowed: boolean;
    loanTransferFromAllowed: boolean;
    loanTransferToAllowed: boolean;
    statementDeliveryAllowed: boolean;
    statementViewingAllowed: boolean;
    statementSSOAllowed: boolean;
    stopPaymentAllowed: boolean;
    textBankingFromAllowed: boolean;
    textBankingToAllowed: boolean;
    achAllowed: boolean;
    wiresAllowed: boolean;
    billPayAllowed: boolean;
    displayHistoryAllowed: boolean;
    displayBalanceAllowed: boolean;
    alertsAllowed: boolean;
    transferCrossEntityAllowed: boolean;
    billPaySSOAllowed: boolean;
    checkStatusAllowed: boolean;
    checkWithdrawalAllowed: boolean;
    orderChecksAllowed: boolean;
    picturePayAllowed: boolean;
    balanceVerificationRequired: boolean;
    sweepTransfersAllowed: boolean;
    federalTaxPaymentsAllowed: boolean;
    rdcSsoAllowed: boolean
  };
  isAsset: boolean;
  isLiability: boolean;
  branchNumber: string;
  routingNumber: string;
  details: [
    {
      value: string;
      label: string;
    }
  ];
  statementDeliveryOption: string;
  statementDeliveryEmail: {
      address: string;
      emailType: string;
    }[];
  statementDeliveryEnrollmentTime: string;
  ownerName: string;
  ownerAddress: {
    streetLine_1: string;
    streetLine_2: string;
    streetLine_3: string;
    city: string;
    state: string;
    country: string;
    province: string;
    postalCode: string;
    postalCodeExtension: string;
    addressType: string;
  },
  isImpactBankingAccount?: boolean;
}

export type AccountDetails = (AccountBase | FinastraAccountDetails) & {
  name: string;
  account_id: string;
}

export interface MatchedPlaidTransaction extends PlaidTransaction {
  match?: string; // company name
  match_type?: MatchType;
  score?: number;
  match_id?: string | ObjectID;
  error?: Error;
  dataCompleteness?: number;
  moderation?: {
    type: MatchType; // some entries in the db are lowercase
    companyId?: string;
  };
  matching?: {
    dedupe_key: string;
    dedupe_type: 'merchant' | 'label';
    mapping_id: string;
  };
  isValuesAligned?: boolean;
  valuesAligned?: MatchRatingInfo[];
  logourl?: string;
  account_info: AccountInstanceSummary
}

export type SpendingBreakdownItem = {
  total: number;
  count: number;
  color: string;
}

export type DashboardTransactionSummary = UserImpact & {
  transactions: MatchedPlaidTransaction[];
  spendingBreakdown: Record<string,SpendingBreakdownItem>;
  totalSpending: number;
  totalTransactions: number;
}