// import { Indicator, Value } from './'
import { Value } from './value'
import { Company, Indicator } from './'

export const SCORES_INDEX = {
  // science-backed targets
  "SBTI": {
    "1.5°C": 1,
    "Well-below 2°C": 0.9,
    "2°C": 0.8,
    "Committed": 0.75
  },
  "Y/N": {
    Y: 1,
    N: 0
  },
  "A to C": {
    A: 1,
    B: 0.5,
    C: 0
  },
  "A to F": {
    "A": 0.95,
    "A-": 0.85,
    "B": 0.75,
    "B-": 0.6,
    "C": 0.5,
    "C-": 0.4,
    "D": 0.25,
    "D-": 0.15,
    "F": 0
  }
}

export const sluggifyName = (name: string): string => {
  return name.toLowerCase().replace(/[.'"$(){}[\]!@,#%&]/g, '').replace(/[ /]+/g, '-')
}

export const normalizeRating = (indicator: Indicator, rating: string | number): number => {
  let score
  const _rating: number = typeof rating === 'string' ? parseFloat(rating) : rating

  const weights = SCORES_INDEX[indicator.scale]
  if (weights) {
    // map a rating to a numerical value
    // A-F, A-C, Y/N, etc
    score = weights[_rating] || 0
  } else {
    // numeric so we can calculate a range; e.g., 0-100, -25-125
    const [min, max] = indicator.scale.split(' to ').map((v) => parseInt(v, 10))
    score = (_rating - min) / (max - min)
  }

  return score
}

export const starRating = (score: number): number => {
  let stars = 0

  switch (true) {
    case score < 0.2:
      stars = 1
      break
    case score < 0.4:
      stars = 2
      break
    case score < 0.6:
      stars = 3
      break
    case score < 0.8:
      stars = 4
      break
    case score >= 0.8:
      stars = 5
      break
    default:
      break
  }

  return stars
}

export const whitelistKeys = (
  obj: Record<string, unknown>,
  keys: (string | Record<string, string[]>)[] // Allow strings or nested key objects
): Record<string, unknown> => {
  const out: Record<string, unknown> = {}

  keys.forEach((key) => {
    if (typeof key === 'string') {
      // Direct key: copy it if it exists in obj
      if (key in obj) {
        out[key] = obj[key]
      }
    } else if (typeof key === 'object' && key !== null) {
      // Nested key: handle each key recursively
      Object.entries(key).forEach(([nestedKey, nestedFields]) => {
        if (nestedKey in obj && typeof obj[nestedKey] === 'object' && obj[nestedKey] !== null) {
          out[nestedKey] = whitelistKeys(
            obj[nestedKey] as Record<string, unknown>, 
            nestedFields
          )
        }
      })
    }
  })

  return out
}

type Dims = {
  height: number;
  width: number;
}

export const dimsScaledToWidth = (targetWidth: number, w: number, h: number): Dims => {
  const multiplier = targetWidth / w
  return {
    width: targetWidth,
    height: h * multiplier
  }
}

export const dimsScaledToHeight = (targetHeight: number, w: number, h: number): Dims => {
  const multiplier = targetHeight / h
  return {
    width: w * multiplier,
    height: targetHeight
  }
}

type PhotoFit = {
  height: number;
  width: number;
  x: number; // offset
  y: number; // offset
}

export const bestPhotoFit = (targetW: number, targetH: number, w: number, h: number): PhotoFit => {
  let x = 0, y = 0
  let dims

  if (w > h) {
    dims = dimsScaledToHeight(targetH, w, h)
    if (dims.width > targetW) {
      // photo is too wide, so constrain it by width
      dims = dimsScaledToWidth(targetW, w, h)
    }
  } else {
    dims = dimsScaledToWidth(targetW, w, h)
    if (dims.height > targetH) {
      dims = dimsScaledToHeight(targetH, w, h)
    }
  }

  if (dims.height === targetH) {
    x = (targetW - dims.width) / 2
  } else {
    y = (targetH - dims.height) / 2
  }

  return { ...dims, x, y }
}

export const shuffle = (array: unknown[]): unknown[] => {
  let currentIndex = array.length
  let randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}

export const titleCase = (str: string): string => {
  return str.toLowerCase().split(' ').map(word => {
    return (word.charAt(0).toUpperCase() + word.slice(1))
  }).join(' ')
}

// TODO: this should be implemented as a static method of indicators once we 
// consolidate ecountabl-types and @ecountabl/util
export const verifyIndicatorConfig = (values: Value[], indicators: Indicator[]): string[] => {
  const warnings = []

  // TODO: account for superceding indicators that are inactive or in different Value assignments
  values.forEach(v => {
    const indicatorsInValue = indicators.filter(i => i.slug === v.slug)
    if (indicatorsInValue.length === 0) {
      warnings.push(`No indicators for Value ${v.label}`)
    }
  })

  indicators.forEach(i => {
    if (i.slug !== undefined && !values.find(v => v.slug === i.slug)) {
      warnings.push(`Could not find Value ${i.slug} for Indicator '${i.label}'`)
    }
  })

  return warnings
}

type Logoish = string | {
  webpurl?: string
  logourl?: string
  logoref?: string
}

// TODO: bulk of this logic could be eliminated with a 
// database migration to consolidate image patterns
export const logoUrl = (l: Logoish, origin?: string): string | undefined => {
  let nameOrUrl: string

  if (typeof l === 'string') {
    nameOrUrl = l
  } else if (l) {
    nameOrUrl = l.webpurl || l.logourl || l.logoref
  }

  if (nameOrUrl && /^https?:\/\//.test(nameOrUrl)) {
    const url = new URL(nameOrUrl)
    if (origin) {
      return url.href.startsWith(origin) ? url.href : url.href.replace(url.origin, origin).replace('/logos.ecountabl.com', '')
    } else {
      return nameOrUrl
    }
  } else if (nameOrUrl && origin) {
    // legacy implementation, most likely uses l.logoref and doesn't include an extension
    const ext = nameOrUrl.split('.').pop()
    if (['png', 'webp', 'jpg', 'jpeg', 'svg', 'gif', 'tiff'].includes(ext?.toLowerCase())) {
      return `${origin}/${nameOrUrl}`
    } else {
      return `${origin}/${encodeURIComponent(nameOrUrl)}.png`
    }
    
  }
}

export const regexLiteralFromString = (str: string): RegExp => {
  // check if the string is a regex literal or a string to check for
  const mainMatch = str.match(/\/(.+)\/.*/)

  // if there is no match, return the regex of the string on its own since no flags possible
  if (!mainMatch) {
    return new RegExp(str)
  }
  // if there is a match, extract the regex and the flags
  const mainRegex = mainMatch[1]
  const optionsMatchArr = str.match(/\/.+\/(.*)/)
  const options = optionsMatchArr?.length >= 2 ? optionsMatchArr[1] : ""

  return new RegExp(mainRegex, options)
}

type formatOptions = {
  roundAmountIfWhole?: boolean
  removeNegatives?: boolean
  shortenLargeNumbers?: boolean
}

const ONE_BILLION = 1_000_000_000
const ONE_MILLION = 1_000_000
const ONE_THOUSAND = 1_000

export const numberFormat = (val: number | bigint | string, format?: formatOptions): string => {
  let _num = val
  if (typeof _num === "string") {
    _num = parseFloat(_num.replace(/[^0-9.-]/g, ""))
  }
  
  let suffix = ""
  let shortenedNum = _num

  if (format?.shortenLargeNumbers) {
    if (Math.abs(_num) >= ONE_BILLION) {
      shortenedNum = _num / ONE_BILLION
      suffix = "B"
    } else if (Math.abs(_num) >= ONE_MILLION) {
      shortenedNum = _num / ONE_MILLION
      suffix = "M"
    } else if (Math.abs(_num) >= ONE_THOUSAND) {
      shortenedNum = _num / ONE_THOUSAND
      suffix = "k"
    }
  }

  // bigint always whole but only matters if we want to make whole
  const makeWhole = (
    format && (format.roundAmountIfWhole || format.shortenLargeNumbers) && 
    ( typeof shortenedNum === "bigint" || shortenedNum % 1 === 0 )
  )

  return (
    new Intl.NumberFormat("en-US", {
      useGrouping: true,
      minimumFractionDigits: makeWhole ? 0 : 2,
      maximumFractionDigits: makeWhole ? 0 : 2,
      signDisplay: format?.removeNegatives ? "never" : "auto",
    }).format(shortenedNum) + suffix
  )
}

export const currencyFormat = (val: number | bigint | string, format?: formatOptions): string => {
  const formatted = numberFormat(val, format)
  // if the number is negative, remove the negative sign and add it back at the front
  if (formatted[0] === "-") {
    return `-$${formatted.slice(1)}`
  }
  // simply return the formatted number with a dollar sign in front
  return `$${formatted}`
}

export const getCompanyDescription = (company: Company): string => {
  if (company.description) {
    return company.description
  }

  const name = company.name || "{company name}"
  const secondHalf = company.segment ? `in the ${company.segment} industry` : "that has been recently added to our collection of businesses"


  return `${name} is a business ${secondHalf}`
}

export const digit2 = (x: number | string): string => {
  return x < 10 ? '0' + x.toString() : x.toString()
}

export const extractDate = (dt: string): Date | undefined => {
  let year, month, day
  let date: Date | undefined = undefined

  const _v = (dt as string).trim()
  let matches: RegExpMatchArray | null
  if (/^\d{4}-\d{2}-\d{2}$/.test(_v)) {
    // yyyy-mm-dd
    [year, month, day] = (_v).split('-').map(n => parseInt(n, 10))
  } else if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(_v)) {
    // val is ISO date format
    date = new Date(_v)
    // eslint-disable-next-line no-cond-assign
  } else if (matches = _v.match(/^\d{1,2}([-/])\d{1,2}[-/]\d{2,4}$/)) {
    // m/d/yy[yy] or m-d-yy[yy]
    // matches[1] is separator; String.match returns null if no matches
    [month, day, year] = _v.split(matches[1]).map(n => parseInt(n, 10))
  } else {
    console.warn('unknown date format', dt)
  }

  let str
  if (year) {
    if (year.toString().length <= 2) {
      const currentYear = new Date().getUTCFullYear() % 100
      year += year >= currentYear ? 1900 : 2000
    }
    // coerce to iso for usage with timezone, assume noon east coast time
    // although this is subject to DST, so it might be off an hour
    str = `${year}-${digit2(month)}-${digit2(day)}T12:00:00.000-04:00`
    date = new Date(str)
  }

  return date
}